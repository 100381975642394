<template>
  <vue-plotly :data="mapData" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

import zorgkantoor_geojson_2017 from '../assets/zorgkantoor_gemeente2017.geojson'
import zorgkantoor_geojson_2018 from '../assets/zorgkantoor_gemeente2018.geojson'
import zorgkantoor_geojson_2019 from '../assets/zorgkantoor_gemeente2019.geojson'
import zorgkantoor_geojson_2020 from '../assets/zorgkantoor_gemeente2020.geojson'
import zorgkantoor_geojson_2021 from '../assets/zorgkantoor_gemeente2021.geojson'


import dementienetwerk_geojson_2017 from '../assets/dementienetwerk_gemeente2017.geojson'
import dementienetwerk_geojson_2018 from '../assets/dementienetwerk_gemeente2018.geojson'
import dementienetwerk_geojson_2019 from '../assets/dementienetwerk_gemeente2019.geojson'
import dementienetwerk_geojson_2020 from '../assets/dementienetwerk_gemeente2020.geojson'
import dementienetwerk_geojson_2021 from '../assets/dementienetwerk_gemeente2021.geojson'

// Site to get geojson: https://cartomap.github.io/nl/wgs84/gemeente_2022.geojson. Just change the year in the url.

export default {
  name: 'Choropleth',
  components: { VuePlotly },
  props: {
    locations: {
      type: Array,
      default : () =>  null
    },
    hoverinfo: {
      type: String,
      default: ''
    },
    z: {
      type: Array,
      default : () =>  null
    },
    text: {
      type: Array,
      default : () =>  null
    },
    hovertemplate:{
      type: String,
      default: ''
    },
    dtick : {
      type: Number,
      default: null
    },
    tick0 : {
      type: Number,
      default: null
    },
    tickmax : {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: () => 440
    },
    zmin: {
      type: Number,
      default: () => 0
    },
    zmax: {
      type: Number,
      default: () => 3000
    },
    colorscale: {
      type: Array,
      default : () =>  null
    },
    zoom: {
      type: Number,
      default: () => 6
    },
    selectedRegion: {
      type: String,
      default: null
    },
    colorbar: {
      ticksuffix: {
        type: String,
        default: ''
      },
    },
    year: {
      type: Number,
      default: null
    },
    level:{
      type: String,
      default: ''
    },
  },
  data () {
    return {
      layout: {
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 20,
          pad: 4},
        mapbox: {
          style: 'white-bg',
          center: {
            lon: 5.104480,
            lat: 52.092876
          },
          zoom: this.zoom
        },
        height: this.height
      },
      options: {
        displayModeBar: false,
        responsive:true,
        maintainAspectRatio: false,
      },
    }
  },

  computed: {
    mapData ()  {
      let geojson

      if (this.level == 'zorgkantoor' || this.level == 'landelijk') {
        if (this.year==2017){
          geojson = zorgkantoor_geojson_2017
        }
        else if (this.year==2018){
          geojson = zorgkantoor_geojson_2018
        }
        else if (this.year==2019){
          geojson = zorgkantoor_geojson_2019
        }
        else if (this.year==2020){
          geojson = zorgkantoor_geojson_2020
        }
        else {
          geojson = zorgkantoor_geojson_2021
        }
      }
      if (this.level == 'dementienetwerk') {
        if (this.year==2017){
          geojson = dementienetwerk_geojson_2017
        }
        else if (this.year==2018){
          geojson = dementienetwerk_geojson_2018
        }
        else if (this.year==2019){
          geojson = dementienetwerk_geojson_2019
        }
        else if (this.year==2020){
          geojson = dementienetwerk_geojson_2020
        }
        else {
          geojson = dementienetwerk_geojson_2021
        }
      }

      this.getRegionColors()

      return [
          {
            type: 'choroplethmapbox',
            marker: 
            {
              line: 
              {
                color: this.getMarkerColors(), 
                width: 1.8
              }
            },
            colorscale: this.colorscale,
            locations: this.locations,
            hoverinfo: 'z+text',
            geojson: geojson,
            text: this.text,
            z: this.z,
            zmin: this.zmin,
            zmax: this.zmax,
            hovertemplate: this.hovertemplate,
            colorbar: {
            ticksuffix: this.colorbar.ticksuffix,
            dtick: this.dtick,
            tick0: this.tick0,
            tickmax: this.tickmax
          },
          }
        ]
    }
  },
  methods: {

    getIndices() {
      if (this.selectedRegion) {
        const selectedIndices = this.locations.reduce((indices, location, index) => {
          if (location === this.selectedRegion || this.text[index] === this.selectedRegion) {
            indices.push(index);
          }
          return indices;
        }, []);
        return selectedIndices;
      }
    },
    getMarkerColors() {
      const indices = this.getIndices();
      const markerColors = this.locations.map((location,index) => indices.includes(index) ? '#144273' : 'transparent');

      return markerColors
    },
    getRegionColors() {
      const indices = this.getIndices();
      const markerColors = this.locations.map((location,index) => indices.includes(index) ? '#144273' : 'transparent');

      return markerColors
    }
}

}

</script>