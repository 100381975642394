<template>
  <div :id="`card-element-${cardId}`">
    <section slot="pdf-content">
      <div class="card down">
        <div class="card-body" v-if="!collapse" :style="fontStyle">
          <slot name="card-body" />
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div class="d-flex flex-row justify-content-start align-items-center">
                        <router-link tabindex="0" :to="link" class="btn custom-button position-relative w-25" style="text-align:left;" type="button" aria-label="Lees meer" aria-pressed="false" name="LeesmeerKnop">
                          Lees meer
                        </router-link>
                        <font-awesome-icon ref="infoIcon" tabindex="0" class="info-button" icon="info-circle" @keydown="handleKeydown" v-b-popover.hover.focus="hovertext" />
                        <font-awesome-icon tabindex="0" class="custom-spacing-left print-button" type="button" icon="download" @click="printPDF()" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

// set a canvas attributes before getting a context as they can only be set once at creation time
HTMLCanvasElement.prototype.getContext = function (origFn) {
  return function (type, attribs) {
    attribs = attribs || {};
    attribs.preserveDrawingBuffer = true;
    return origFn.call(this, type, attribs);
  };
}(HTMLCanvasElement.prototype.getContext);

export default {
  name: 'CardMap',
  // components: {
  //       VueHtml2pdf
  //     },
  props: {
    cardId: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false,
    },
    hovertext: {
      type: String,
      required: false,
    },
    //hovertext: String,
    fontsize: {
      type: Number,
      default: 12
    },
  },
  data() {
    return {
      collapse: false,
      hover: false,
      height: 550,
    }
  },
  computed: {
    fontStyle() {
      return `font-size: ${this.fontsize}px;`
    }
  },
  mounted() {
    // a global event listener for "Esc" keypress
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    // removing the global event listener when the component is destroyed
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    handleKeydown(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        // calls the 'close' event when the "Esc" key is pressed
        this.$refs.infoIcon.blur();
      }
    },
    toggleCollapse: function () {
      this.collapse = !this.collapse
    },
    printPDF() {
      // get component size
      const cardElement = document.getElementById(`card-element-${this.cardId}`)
      const scale = 1.5

      // dividing the pixel values by the pixel density as 1 point is equal to 1/72 of an inch
      const width = cardElement.offsetWidth / 96 * 72 * scale
      const height = cardElement.offsetHeight / 96 * 72 * scale

      html2canvas(cardElement)
        .then(canvas => {
          const pdf = new jsPDF({ orientation: 'landscape', documentTitle: 'Your PDF Title' },
            'pt', [width, height])
          pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0)
          pdf.setLanguage("nl-NL")
          pdf.save(`${this.cardId}-card.pdf`)
        })
    },
  },
}

</script>

<style scoped>
/*
.card{
  border: none;
}
*/

.card-header:hover {
  cursor: pointer;
}

.card-header {
  height: 30px;
  padding: 5px 5px 5px 20px;
  color: white;
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
  text-align: left;
}

.card-body {
  background-color: white !important;
  border: none;
  text-align: left;
  /*  max-height: 80vh;
  overflow-y: auto;*/
}

.right {
  margin-left: 10px;
}

.col-sm-1 {
  margin-right: -20px;
}

.custom-spacing {
  margin-right: 5px;
}

.custom-button:focus,
.custom-button:hover {
  outline: none !important;
  box-shadow: none;
  color: var(--color-2);
  background-color: var(--color-12);
}

.custom-spacing-left {
  margin-left: 20px;
}

.print-button {
  color: var(--color-1);
  font-size: 16px;
  margin-top: 2rem;
  transition: all .3s;
}

.info-button {
  color: var(--color-1);
  font-size: 16px;
  transition: all .3s;
}

.info-button:focus,
.info-button:hover,
.print-button:focus,
.print-button:hover {
  outline: none !important;
  box-shadow: none;
  color: var(--color-10);
  -webkit-transform: scale(2);
}
</style>
