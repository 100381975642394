<template>
  <div class="sidebar p-5" id="dementie-information">
    <div class="dashboard">
      <h1 class="title">{{ themes.find(theme => theme.value === selectedTheme).text.charAt(0).toUpperCase() +
        themes.find(theme => theme.value === selectedTheme).text.slice(1) }}</h1>

      <div v-if="selectedLevel === 'landelijk'" class="d-flex align-items-center landelijk-warning" role="alert"
        aria-live="assertive" aria-atomic="true">
        <p class="landelijk-warning-message">Deze pagina is niet toegankelijk via het Landelijk niveau.</p>
      </div>


      <div class="row">
        <div class="col-sm-12 down">
          <card-map :cardId="'diagnose-map-1'" :hovertext="yearSpecificHovertext" :link="routeLink">
            <div slot="card-body">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <!-- <h1 slot="card-header" class="title">{{ themes.find(theme => theme.value === selectedTheme).text.charAt(0).toUpperCase() + themes.find(theme => theme.value === selectedTheme).text.slice(1) }}</h1> -->
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <div class="card-text">
                            In het thema
                            <label for="selectThema"></label>
                            <select aria-label="Selecteer het thema" id="selectThema" v-model="selectedTheme"
                              style="color: var(--color-1)" size="sm" class="custom-dropdown custom-control"
                              ref="firstCategory" @change="setSelectWidthCategory()">
                              <option class="select-form-text" v-for="theme in themes" :value="theme.value"
                                :key="theme.value">{{ theme.text }}</option>
                            </select>
                            ben ik geïnteresseerd in de regionale verschillen in
                            <label for="selectRegionaleVerschillen"></label>
                            <select aria-label="Selecteer de regionale verschillen" id="selectRegionaleVerschillen"
                              v-model="selectedOption" size="sm" class="custom-dropdown custom-control"
                              ref="firstOption" style="color: var(--color-1)" @change="setSelectWidthOption()">
                              <option class="select-form-text"
                                v-for="option in selectedTheme ? themes.find(t => t.value === selectedTheme).options : []"
                                :value="option.value" :key="option.value">{{ option.text }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="container">
                      <div class="row">
                        <div class="col">
                          <div class="card-text">
                            <p class="selected-option-header">Toelichting op de grafiek</p>
                            <p class="selected-option-text">{{ yearSpecificOptionInformation }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="hasError" class="container custom-space error-text">
                      <p>Uitsluitend op zorgkantoor niveau.</p>
                    </div>

                    <div v-if="!hasError" class="container custom-space">
                      <div class="row">
                        <div class="col">
                          <div class="d-inline">
                            <p class="max-min-maptext">{{ this.max_percentage }}%</p>
                            <p class="p-map"> regio met hoogste aandeel</p>
                            <b-progress aria-label="Voortgangsbalk die de regio met het hoogste aandeel weergeeft."
                              :value="max_percentage" :max="100" class="w-50 custom-space"
                              :variant="'special'"></b-progress>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="d-inline">
                            <p class="selected-maptext">{{ this.region_percentage }}%</p>
                            <p class="p-selected"> in geselecteerde regio</p>
                            <b-progress
                              aria-label="Voortgangsbalk die het percentage in de geselecteerde regio weergeeft."
                              :value="region_percentage" :max="100" class="w-50 custom-space"
                              :variant="'selected'"></b-progress>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="d-inline">
                            <p class="landelijk-maptext">{{ this.landelijk_percentage }}%</p>
                            <p class="p-landelijk"> in landelijk gemiddelde</p>
                            <b-progress
                              aria-label="Voortgangsbalk die het percentage in het nationale gemiddelde weergeeft."
                              :value="landelijk_percentage" :max="100" class="w-50 custom-space"
                              :variant="'success'"></b-progress>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="d-inline">
                            <p class="max-min-maptext">{{ this.min_percentage }}%</p>
                            <p class="p-map"> regio met laagste aandeel</p>
                            <b-progress
                              aria-label="Voortgangsbalk die het percentage toont in de regio met het laagste aandeel."
                              :value="min_percentage" :max="100" class="w-50 custom-space"
                              :variant="'special'"></b-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col align-self-center">
                    <choropleth v-if="load.graphOne" :colorscale="colorscale" :zmax='zmax' :zmin="zmin" :z="percentage"
                      :locations="location_factors" :text="location_names" :tickmax="zmax" :tick0="zmin"
                      :selectedRegion="selectedRegion" :colorbar="{ ticksuffix: '%' }"
                      :hovertemplate="'<extra><br>%%{z:.1f}<br>%{text}<br></extra>'" :year="selectedYear"
                      :level="selectedLevel" />
                  </div>
                </div>
              </div>
            </div>
          </card-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Choropleth from '@/components/ChoroplethRegional'
import CardMap from '../components/CardMap'
import { mapGetters } from 'vuex'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Overzicht',
  },
  components: { CardMap, Choropleth },

  data() {
    return {
      hasError: false,
      themes: [
        {
          value: '_ondersteuning', text: 'wmo',
          options: [
            { value: 'wmo', text: 'wmo gebruik' },
            { value: 'ondersteuning_thuis', text: 'ondersteuning thuis' },
            { value: 'hulp_huishouden', text: 'hulp bij huishouden' },
            { value: 'hulpmiddelen_diensten', text: 'hulpmiddelen en diensten' }
          ]
        },
        {
          value: '_medicatie', text: 'medicatie', options: [
            { value: 'gebruikt_medicatie', text: 'medicijngebruik' },
            { value: 'psychofarmaca', text: 'psychofarmaca' },
            { value: 'polyfarmacieZonderJ', text: 'polyfarmacie' }
          ]
        },
        {
          value: '_leveringsvorm', text: 'langdurige zorg', options: [
            { value: 'natura', text: 'gebruik van zorg in natura' },
            { value: 'pgb', text: 'gebruik van pgb' },
            { value: 'modulair_pakket_thuis', text: 'gebruik van mpt' },
            { value: 'volledig_pakket_thuis', text: 'gebruik van vpt' }
          ]
        },
        {
          value: '_migratieachtergrond',
          text: 'kenmerken',
          options: [
            { value: 'migratieachtergrond', text: 'migratieachtergrond' },
            { value: 'typehuishouden', text: 'woonsituatie' },
            { value: 'leeftijdscategorie65', text: 'aandeel dementie' },
            { value: 'leeftijdscategorie80', text: 'leeftijdscategorie 80+' },
            { value: 'laagsteinkomen', text: 'laagste inkomen' },
            { value: 'hoogsteinkomen', text: 'hoogste inkomen' }
          ]
        }
      ],
      selectedTheme: '_ondersteuning',
      selectedOption: 'wmo',
      selectedOptionInformation: 'Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van minimaal één Wmo-gefinancierde voorziening. Er is geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn.',
      routeLink: '/Factsheet/4',
      max_percentage: 0,
      min_percentage: 0,
      landelijk_percentage: 0,
      region_percentage: 0,
      location_names: [],
      location_factors: [],

      hovertext: 'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat enige vorm van Wmo zorg ontvangt.',

      perc: [],
      load: {
        graphOne: false,
      },
      //     colorscale: [
      // ['0.0', 'rgb(205, 230, 246)'],
      // ['0.25', 'rgb(115, 182, 224)'],
      // ['0.50', 'rgb(37, 116, 169)'],
      // ['0.75', 'rgb(16, 63, 105)'],
      // ['1.0', 'rgb(20, 66, 115)']
      // ]
      colorscale: [
        ['0.0', 'rgb(235, 245, 255)'],
        ['0.25', 'rgb(200, 225, 245)'],
        ['0.50', 'rgb(165, 205, 235)'],
        ['0.75', 'rgb(130, 185, 225)'],
        ['1.0', 'rgb(95, 165, 215)']]
    }
  },

  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level, this.selectedTheme, this.selectedOption)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel, this.selectedTheme, this.selectedOption)
      this.selectedOption, this.routeLink = this.getOptionLink()
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel, this.selectedTheme, this.selectedOption)
      this.selectedOption, this.routeLink = this.getOptionLink()
    },
    'selectedOption': function () {
      this.loadAllData(this.$store.state.selectedLevel, this.selectedTheme, this.selectedOption)
      this.setSelectWidthOption()
      this.hovertext, this.selectedOptionInformation = this.getHoverText()
    },
    'selectedTheme': function () {
      this.selectedOption, this.routeLink = this.getOptionLink()
      this.loadAllData(this.$store.state.selectedLevel, this.selectedOption)
      this.setSelectWidthCategory();

      // to fix the width of selectedOption
      this.$nextTick(() => {
        this.setSelectWidthOption();
      });

    }
  },
  methods: {
    loadAllData(level) {
      let api_link = ''
      if (level === 'zorgkantoor' || level === 'landelijk') {
        if (this.selectedTheme == '_migratieachtergrond') {
          if (this.selectedOption == 'typehuishouden') {
            api_link = '_huishouden'
          }
          else if (this.selectedOption == 'leeftijdscategorie80') {
            api_link = '_leeftijd'
          }
          else if (this.selectedOption == 'migratieachtergrond') {
            api_link = '_migratieachtergrond'
          }
          else if (this.selectedOption == 'laagsteinkomen') {
            api_link = '_inkomen'
          }
          else if (this.selectedOption == 'hoogsteinkomen') {
            api_link = '_inkomen'
          }
          else if (this.selectedOption == 'leeftijdscategorie65') {
            api_link = '_totaal'
          }


          this.$http.get(`/api/zorgkantoor${api_link}/get_selection/?jaar=${this.$store.state.selectedYear}&selection=${this.selectedOption}`).then(
            (response) => {
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.percentage = response.data.map(d => (d.percentage) * 100).map(v => v.toFixed(2))
              this.min_percentage = Math.round(Math.min(...this.percentage))
              this.max_percentage = Math.round(Math.max(...this.percentage))
              this.zmin = this.min_percentage
              this.zmax = this.max_percentage

              const landelijk_data = response.data.find(d => d.zorgkantoorregionaam === 'Landelijk')
              this.landelijk_percentage = landelijk_data ? (landelijk_data.percentage * 100).toFixed(0) : null
              this.location_factors = response.data.map(d => d.zorgkantoorregiocode)
              this.location_names = response.data.map(d => d.zorgkantoorregionaam)
              this.region_percentage = (response.data.filter(x => x.zorgkantoorregionaam === this.$store.state.selectedRegion).map(d => d.percentage * 100)[0]).toFixed(0)
              this.load.graphOne = true
              this.hasError = false
            }
          )
        }
        else {
          this.$http.get(`/api/zorgkantoor${this.selectedTheme}/get_selection/?jaar=${this.$store.state.selectedYear}&selection=${this.selectedOption}`).then(
            (response) => {
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.percentage = response.data.map(d => (d.percentage) * 100).map(v => v.toFixed(2))
              this.min_percentage = Math.round(Math.min(...this.percentage))
              this.max_percentage = Math.round(Math.max(...this.percentage))

              if (this.selectedOption === 'gebruikt_medicatie') {
                this.zmin = this.min_percentage - 10
                this.zmax = 100
              }
              else {
                this.zmin = this.min_percentage
                this.zmax = this.max_percentage
              }

              const landelijk_data = response.data.find(d => d.zorgkantoorregionaam === 'Landelijk')
              this.landelijk_percentage = landelijk_data ? (landelijk_data.percentage * 100).toFixed(0) : null
              this.location_factors = response.data.map(d => d.zorgkantoorregiocode)
              this.location_names = response.data.map(d => d.zorgkantoorregionaam)
              this.region_percentage = (response.data.filter(x => x.zorgkantoorregionaam === this.$store.state.selectedRegion).map(d => d.percentage * 100)[0]).toFixed(0)
              this.load.graphOne = true
              this.hasError = false
            }
          )
        }
      }


      else {
        if (this.selectedTheme === '_leveringsvorm' | this.selectedTheme === '_us_zorggebruik') {
          this.load.graphOne = false
          this.hasError = true
          return;
        }

        else {
          let api_link = ''
          if (level === 'dementienetwerk') {
            if (this.selectedTheme == '_migratieachtergrond') {
              if (this.selectedOption == 'typehuishouden') {
                api_link = '_huishouden'
              }
              else if (this.selectedOption == 'leeftijdscategorie80') {
                api_link = '_leeftijd'
              }
              else if (this.selectedOption == 'migratieachtergrond') {
                api_link = '_migratieachtergrond'
              }
              else if (this.selectedOption == 'laagsteinkomen') {
                api_link = '_inkomen'
              }
              else if (this.selectedOption == 'hoogsteinkomen') {
                api_link = '_inkomen'
              }
              else if (this.selectedOption == 'leeftijdscategorie65') {
                api_link = '_totaal'
              }

              this.$http.get(`/api/dementienetwerk${api_link}/get_selection/?jaar=${this.$store.state.selectedYear}&selection=${this.selectedOption}`).then(
                (response) => {
                  this.percentage = response.data.map(d => (d.percentage) * 100).map(v => v.toFixed(2))
                  this.min_percentage = Math.round(Math.min(...this.percentage))
                  this.max_percentage = Math.round(Math.max(...this.percentage))
                  this.zmin = this.min_percentage
                  this.zmax = this.max_percentage

                  const landelijk_data = response.data.find(d => d.dementienetwerknaam === 'Landelijk')
                  this.landelijk_percentage = landelijk_data ? (landelijk_data.percentage * 100).toFixed(0) : null
                  this.location_factors = response.data.map(d => d.dementienetwerkcode)
                  this.location_names = response.data.map(d => d.dementienetwerknaam)
                  this.region_percentage = (response.data.filter(x => x.dementienetwerknaam === this.$store.state.selectedRegion).map(d => d.percentage * 100)[0]).toFixed(0)
                  this.load.graphOne = true
                  this.hasError = false
                }
              )
            }
            else {
              this.$http.get(`/api/dementienetwerk${this.selectedTheme}/get_selection/?jaar=${this.$store.state.selectedYear}&selection=${this.selectedOption}`).then(
                (response) => {
                  this.percentage = response.data.map(d => (d.percentage) * 100).map(v => v.toFixed(2))
                  this.min_percentage = Math.round(Math.min(...this.percentage))
                  this.max_percentage = Math.round(Math.max(...this.percentage))
                  this.zmin = this.min_percentage
                  this.zmax = this.max_percentage

                  const landelijk_data = response.data.find(d => d.dementienetwerknaam === 'Landelijk')
                  this.landelijk_percentage = landelijk_data ? (landelijk_data.percentage * 100).toFixed(0) : null
                  this.location_factors = response.data.map(d => d.dementienetwerkcode)
                  this.location_names = response.data.map(d => d.dementienetwerknaam)
                  this.region_percentage = (response.data.filter(x => x.dementienetwerknaam === this.$store.state.selectedRegion).map(d => d.percentage * 100)[0]).toFixed(0)
                  this.load.graphOne = true
                  this.hasError = false
                })
            }
          }
        }
      }
    },
    getOptionLink() {
      if (this.selectedTheme == '_ondersteuning') {
        this.selectedOption = 'wmo'
        this.routeLink = '/Factsheet/4'
      }
      if (this.selectedTheme == '_medicatie') {
        this.selectedOption = 'gebruikt_medicatie'
        this.routeLink = '/Factsheet/5'
      }
      if (this.selectedTheme == '_leveringsvorm') {
        this.selectedOption = 'natura'
        this.routeLink = '/Factsheet/2'
      }
      if (this.selectedTheme == '_us_zorggebruik') {
        this.selectedOption = 'enigGebruikWmo'
        this.routeLink = '/Factsheet/7'
      }
      if (this.selectedTheme == '_migratieachtergrond') {
        this.selectedOption = 'migratieachtergrond'
        if (this.selectedOption == 'leeftijdscategorie65') {
          this.routeLink = '/Identificatie'
        } else {
          this.routeLink = '/Factsheet/1'
        }
      }
      this.setSelectWidthOption()
      return this.selectedOption, this.routeLink
    },

    setSelectWidthCategory() {
      let padding = 0;
      const select = this.$refs.firstCategory;
      const selectedOption = select.selectedOptions[0];

      if (selectedOption.value == '_ondersteuning' || selectedOption.value == '_migratieachtergrond') {
        padding = 4;
      }
      else {
        padding = 2; // adjust the padding as needed
      }
      select.style.width = `${selectedOption.text.length + padding}ch`;
    },
    setSelectWidthOption() {
      const select = this.$refs.firstOption;
      const selectedOption = select.selectedOptions[0];
      const padding = 2; // adjust the padding as needed
      select.style.width = `${selectedOption.text.length + padding}ch`;
    },

  },
  mounted() {
    (async () => {
      try {
        await this.loadAllData(this.selectedLevel)

        this.setSelectWidthCategory()
        this.setSelectWidthOption()
      } catch (error) {
        console.log(error)
      }
    })()

  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear']),
    yearSpecificHovertext() {
      if (this.selectedTheme == '_ondersteuning') {
        if (this.selectedOption === 'wmo') {
          return `De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar ${this.$store.state.selectedYear} deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo gefinancierde ondersteuning thuis ontvangt.`;
        }
        else if (this.selectedOption === 'ondersteuning_thuis') {
          return `De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar ${this.$store.state.selectedYear} deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo gefinancierde hulpmiddelen of diensten ontvangt.`;
        }
        else if (this.selectedOption === 'hulp_huishouden') {
          return `De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar ${this.$store.state.selectedYear} deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo gefinancierde hulp bij het huishouden ontvangt.`;
        }
        else if (this.selectedOption === 'hulpmiddelen_diensten') {
          return `De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar ${this.$store.state.selectedYear} deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo gefinancierde hulpmiddelen of diensten ontvangt.`;
        }
      }
      else if (this.selectedTheme == '_medicatie') {
        if (this.selectedOption === 'gebruikt_medicatie') {
          return `In deze grafiek is het aantal thuiswonende mensen met dementie dat medicatie gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven. Bron: Medicatietab van CBS door Zorginstituut Nederland, met daarin medicijnverstrekkingen aan personen in de Basis Registratie Personen. Medicijnen verstrekt tijdens ziekenhuisopname of door verpleeghuis zijn niet meegenomen.`;
        }
        else if (this.selectedOption === 'psychofarmaca') {
          return `In deze grafiek zijn de mensen met psychofarmacaverstrekkingen als percentage van het totaal aantal mensen in de bevolkingsgroep weergegeven. De informatie over de algemene bevolkingsgroepen komt uit: https://opendata.cbs.nl/#/CBS/nl/dataset/81071ned/table?defaultview. De informatie over medicatiegebruik van mensen met dementie komt uit het Medicatietab van CBS door Zorginstituut Nederland.`;
        }
        else if (this.selectedOption === 'polyfarmacieZonderJ') {
          return `In deze grafiek is het aantal thuiswonende mensen met dementie met polyfarmacie (vijf of meer verschillende soorten medicijnen) als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven. Bron: Medicatietab van CBS door Zorginstituut Nederland, met daarin medicijnverstrekkingen aan personen in de Basis Registratie Personen. Medicijnen verstrekt tijdens ziekenhuisopname of door verpleeghuis zijn niet meegenomen.`;
        }
      }
      else if (this.selectedTheme == '_leveringsvorm') {
        if (this.selectedOption === 'natura') {
          return `Bij het bepalen of iemand is opgenomen in een Wlz-instelling is gebruik gemaakt van de gegevens van het CAK (GEBWLZTAB), waarbij de opnamedatum is gedefinieerd als de datum waarop de levering van zorg in natura in een instelling is gestart (en gebruik van andere leveringsvormen dus wordt genegeerd). Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorniveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.`;
        }
        else if (this.selectedOption === 'pgb') {
          return `Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorniveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.`;
        }
        else if (this.selectedOption === 'modulair_pakket_thuis') {
          return `Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorniveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.`;
        }
        else if (this.selectedOption === 'volledig_pakket_thuis') {
          return `Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorniveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.`;
        }
      }
      else if (this.selectedTheme == '_migratieachtergrond') {
        if (this.selectedOption === 'migratieachtergrond') {
          return 'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.';
        }
        else if (this.selectedOption === 'typehuishouden') {
          return 'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Het aantal mensen dat in een zorginstelling woont komt niet overeen met het aantal mensen dat WLZ-zorg met verblijf ontvangt dat elders gerapporteerd wordt. Dat komt omdat voor deze grafiek gebruik wordt gemaakt van een andere bron: het Basisregister Personen (BRP). Pas als iemand een verhuizing naar een zorginstelling doorgeeft wordt hij/zij als bewoner van een zorginstelling aangemerkt. Daarnaast dient de locatie van de zorginstelling aangemerkt als zodanig aangemerkt te zijn in het BRP. Tot slot nemen we hier de huishoudenssamenstelling op 1 januari als uitgangspunt en wordt dus iedereen die gedurende het jaar naar een zorginstelling verhuist niet meegenomen in deze cijfers.';
        }
        else if (this.selectedOption === 'leeftijdscategorie80') {
          return 'Leeftijd op 1 januari. Alleen observaties voor mensen van 39 jaar (die worden 40 in kalenderjaar) of ouder behouden.';
        }
        else if (this.selectedOption === 'laagsteinkomen') {
          return 'Deze grafiek toont het gestandaardiseerd huishoudensinkomen van mensen met dementie. Het inkomen is niet gecorrigeerd voor inflatie. De 4 categorieën zijn gebaseerd op het gestandaardiseerd huishoudensinkomen. Huishoudens met een ontbrekend of negatief inkomen zijn ingedeeld in de laagste categorie inkomen.';
        }
        else if (this.selectedOption === 'hoogsteinkomen') {
          return 'Deze grafiek toont het gestandaardiseerd huishoudensinkomen van mensen met dementie. Het inkomen is niet gecorrigeerd voor inflatie. De 4 categorieën zijn gebaseerd op het gestandaardiseerd huishoudensinkomen. Huishoudens met een ontbrekend of negatief inkomen zijn ingedeeld in de laagste categorie inkomen.';
        }
        else if (this.selectedOption === 'leeftijdscategorie65') {
          return 'Het aantal mensen boven de 65 met dementie in de regio als percentage van alle mensen boven de 65 (met en zonder dementie) in de regio.';
        }
      }

      return '';
    },
    yearSpecificOptionInformation() {
      if (this.selectedTheme == '_ondersteuning') {
        if (this.selectedOption === 'wmo') {
          return 'Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van minimaal één Wmo-gefinancierde voorziening. Er is geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn.';
        }
        else if (this.selectedOption === 'ondersteuning_thuis') {
          return 'Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van Wmo gefinancierde ondersteuning thuis. Ondersteuning thuis omvat persoonlijke verzorging, begeleiding, kortdurend verblijf, dagbesteding en andere groepsgerichte ondersteuning.';
        }
        else if (this.selectedOption === 'hulp_huishouden') {
          return 'Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van Wmo gefinancierde hulp bij het huishouden. Hulp bij huishouden omvat alle ondersteuning bij het huishouden.';
        }
        else if (this.selectedOption === 'hulpmiddelen_diensten') {
          return 'Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat gebruik maakt van Wmo gefinancierde hulpmiddelen en diensten. Als onderdeel van hulpmiddelen en diensten worden alle woon- en vervoersdiensten en voorzieningen, rolstoelen en overige hulpmiddelen meegenomen in het overzicht.';
        }
      }
      else if (this.selectedTheme == '_medicatie') {
        if (this.selectedOption === 'gebruikt_medicatie') {
          return 'In aflopende volgorde van ofwel zorgkantoren ofwel dementienetwerken is het percentage te zien van thuiswonende mensen met dementie dat in ' + this.$store.state.selectedYear + ' medicatie heeft voorgeschreven gekregen.';
        }
        else if (this.selectedOption === 'psychofarmaca') {
          return 'In dit overzicht is het percentage te zien van thuiswonende mensen met dementie dat in ' + this.$store.state.selectedYear + ' psychofarmaca voorgeschreven heeft gekregen.';
        }
        else if (this.selectedOption === 'polyfarmacieZonderJ') {
          return 'In dit overzicht is het percentage te zien van thuiswonende mensen met dementie dat in ' + this.$store.state.selectedYear + ' polyfarmacie heeft voorgeschreven gekregen.';
        }
      }
      else if (this.selectedTheme == '_leveringsvorm') {
        if (this.selectedOption === 'natura') {
          return 'In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van een PGB ten opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op zorgkantoorregio niveau beschikbaar.';
        }
        else if (this.selectedOption === 'pgb') {
          return '';
        }
        else if (this.selectedOption === 'modulair_pakket_thuis') {
          return 'In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van modulair pakket thuis ten opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op zorgkantoorregio niveau beschikbaar.';
        }
        else if (this.selectedOption === 'volledig_pakket_thuis') {
          return 'In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van volledig pakket thuis ten opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op zorgkantoorregio niveau beschikbaar.';
        }
      }
      else if (this.selectedTheme == '_migratieachtergrond') {
        if (this.selectedOption === 'migratieachtergrond') {
          return 'In onderstaand diagram is te zien hoe het geselecteerde zorgkantoor of dementienetwerk zich verhoudt tot het landelijk gemiddelde op het gebied van migratieachtergrond.';
        }
        else if (this.selectedOption === 'typehuishouden') {
          return 'In onderstaand diagram is te zien welk aandeel van mensen met dementie een eenpersoons huishouden heeft.';
        }
        else if (this.selectedOption === 'leeftijdscategorie80') {
          return 'In onderstaand diagram laat zien welk aandeel van de mensen met dementie 80 jaar of ouder is.';
        }
        else if (this.selectedOption === 'laagsteinkomen') {
          return 'Onderstaand diagram laat zien welk aandeel van de mensen met dementie een inkomen heeft kleiner dan €15.000. Het percentage mensen met een inkomen onder de 15.000 euro neemt af over de jaren voor vrijwel elke regio.';
        }
        else if (this.selectedOption === 'hoogsteinkomen') {
          return 'Onderstaand diagram laat zien welk aandeel van de mensen met dementie een inkomen heeft hoger dan €30.000. Het percentage mensen met een inkomen onder de 15.000 euro neemt af over de jaren voor vrijwel elke regio.';
        }
        else if (this.selectedOption === 'leeftijdscategorie65') {
          return 'Het aantal mensen boven de 65 met dementie in de regio als percentage van alle mensen boven de 65 (met en zonder dementie) in de regio.';
        }
      }

      return '';
    }
  }
}

</script>

<style>
.title {
  margin: 1rem 0 1rem 0;
  font-style: normal;
  font-weight: bold;
  color: var(--color-1);
}

.card-text {
  margin: 1rem 0 1rem 0;
  font-style: normal;
  color: var(--color-10);
  font-size: 15px;
}

.selected-option-header {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 20px;
  color: var(--color-12);
}

.selected-option-text {
  font-style: italic;
  font-weight: bold;
  color: #4A4D51;
}

.error-text {
  margin: 1rem 0 1rem 0;
  font-style: normal;
  color: var(--color-10);
  font-size: 15px;
  font-weight: bold;
}

.custom-space {
  margin: 1rem 0 1rem 0;
}

.custom-dropdown {
  background-color: transparent;
  color: var(--color-1);
  font-weight: bold;
}

.custom-control {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  font-weight: bold;
}

.custom-control:focus {
  outline: none !important;

}

.max-min-maptext {
  font-size: 2em;
  font-style: normal;
  color: var(--color-10);
  letter-spacing: 1px;
  font-weight: bold;
  display: inline;
}

.landelijk-maptext {
  font-style: normal;
  font-size: 2em;
  color: var(--color-11);
  letter-spacing: 1px;
  font-weight: bold;
  display: inline;
}

.selected-maptext {
  font-style: normal;
  font-size: 2em;
  color: var(--color-1);
  letter-spacing: 1px;
  font-weight: bold;
  display: inline;
}

.p-map {
  font-style: normal;
  display: inline;
  letter-spacing: 1px;
  color: var(--color-10);
}

.p-landelijk {
  font-style: normal;
  display: inline;
  letter-spacing: 1px;
  color: var(--color-11);
}

.p-selected {
  font-style: normal;
  display: inline;
  letter-spacing: 1px;
  color: var(--color-1);
}

.hr-maptext {
  display: block;
  height: 50px;
  width: 100px;
  background-color: black;
  margin-right: 10px;
}

.b-progress-success {
  background-color: var(--color-11);
}

.custom-button {
  text-align: left;
  padding-left: 6px;
  color: var(--color-1);
  background: var(--color-9);
  border: none;
  outline: none;
}

.custom-button:hover {
  text-align: left;
  padding-left: 6px;
  color: var(--color-2);
  background: var(--color-1);
  border: none;
  outline: none;
}

.bg-special {
  background-color: var(--color-10) !important;
  font-weight: bold;
}

.bg-selected {
  background-color: var(--color-1) !important;
  font-weight: bold;
}

.select-form-text {
  font-weight: bold;
  color: var(--color-1);
}

.landelijk-warning {
  background-color: #f77b72;
  padding: 3rem;
  border-radius: 15px;
  height: 50px;
  width: 100%;
  border: 5px solid #f6685e;
}

.landelijk-warning-message {
  font-weight: bold;
  color: var(--color-2);
}
</style>